import React, { Fragment, useEffect, useState } from "react";
// import Dropdown from "react-dropdown";
import { options } from "./functions";
import { useDispatch } from "react-redux";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import { Icon } from "@iconify/react";
import { updateLanguage } from "../../store/languageReduces";
import "./syle.css";

function LanguageConverter() {
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLangaued] = useState("ar");

  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const updateLaguageData = (lang) => {
      dispatch(updateLanguage(lang));
      setSelectedLangaued(lang);
      onClose();
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu
          title={<img src="../../assests/icons/icon-lang.svg" alt="" />}
          activeKey={selectedLanguage}
          onSelect={updateLaguageData}
        >
          {options.map((item, index) => {
            return (
              <Dropdown.Item className="languageItem" eventKey={item.value}>
                {item.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <Fragment>
      <Whisper placement="bottomStart" trigger="click" speaker={renderMenu}>
        <IconButton
          appearance="primary"
          icon={<img src={require("../../assests/icons/icon-lang.svg").default} alt="" />}
          circle
        />
      </Whisper>
    </Fragment>
  );
}

export default LanguageConverter;
