import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import { getBranched, updateBranch } from "../../store/branchReducer";
import { Icon } from "@iconify/react";
import axios from "axios";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { resetcart } from "../../store/cartReducer";
function Branches() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.lang);
  useEffect(() => {
    dispatch(getBranched({language}));
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location)
  const getBranches = useSelector((state) => state.branches.branches);
  // console.log(getBranches)
  const branches = useSelector((state) => state.branches);
  const getBranch = useSelector((state) => state.branches.branch);
  const resetCart = resetcart;
  const [newbranch,setnewbranch]=useState({});
  // const updatebranchData = (lang) => {
  //   dispatch(updateBranch(JSON.stringify(lang)));
  // };
  const [showchangebranch,setshowchangebranch]=useState(false);
  const updatebranchData = (lang) => {
    // console.log(lang)
    setnewbranch(lang);
    setshowchangebranch(true);
    // dispatch(updateBranch(JSON.stringify(lang)));
  };

  const updateaftermodel=()=>{
    dispatch(updateBranch(JSON.stringify(newbranch)));
  }

  // if (!getBranch && location.pathname != "/branches") {
  //   return navigate("/branches")
  // }

  const renderMenu = ({ onClose, left, top, className }, ref) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu
          style={{
            background: "var(--main-color)",
            padding: "4px",
            borderRadius: "5px",
          }}
          activeKey={"getBranch.name"}
          onSelect={updatebranchData}
        >
          {!branches.loading && getBranches && getBranches.length ? (
            getBranches.map((item, index) => {
              return (
                <Dropdown.Item
                  eventKey={item}
                >
                  {" "}
                  <span style={{ color: "White" }} className="branch_item">{language === "ar" ? item.name : item.name_en}</span>{" "}
                </Dropdown.Item>
              );
            })
          ) : (
            <Dropdown.Item> لا يوجد فروع </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <Whisper placement="bottomStart" trigger="click" speaker={renderMenu}>
      <p className="changeBranch">
        <img src={require("../../assests/icons/icon-location.svg").default} />
        {" "}
        {getBranch && getBranch != "undefined" ? (
          <span style={{ color: "white" }}>{language === "ar" ? getBranch?.name : getBranch?.name_en}</span>
        ) : null}
          {
        showchangebranch?(
          <div className="showmodelbranch">
            <p>{language=='ar'?"سوف يتم مسح المنتجات من العربه إذا غيرت الفرع":"product in cart will deleted when you change branch"}</p>
            <div className="actions">
              <button
                onClick={()=>{
                  updateaftermodel()
                }}
              >{language=='ar'?'تغيير':'change'}</button>
              <button
                onClick={()=>{
                  setshowchangebranch(false)
                }}
              >{language=='ar'?'تراجع':'return'}</button>
            </div>
          </div>
        ):(null)
      }
      </p>
    </Whisper>
  );
}

export default Branches;
