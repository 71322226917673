import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const center = {
  lat: 30.79104837723118,
  lng: 31.003953975123654,
};

function MyComponent({ onPress, locdata }) {
  // console.log(locdata)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBaUmZqLrBQj3LQHChTJOHJuqvDbg_lbAU",
  });

  const [map, setMap] = React.useState(null);
  const [sourceLoc, setSourceLocation] = useState({});

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const fetchLocationName = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: lat, lng: lng };

    try {
      const response = await geocoder.geocode({ location: latlng });
      // console.log(response);
      if (response?.results && response?.results.length > 0) {
        const locationName = response.results[0]?.formatted_address;

        return response.results[0]?.formatted_address;
      }
    } catch (error) {
      console.error('Error fetching location name:', error);
    }

    return ''; // Return an empty string if location name couldn't be fetched
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={locdata}
      zoom={3}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={async (e) => {
        // console.log(e)
        // console.log(e.Ya.x)
        // console.log(e.Ya.y)
        // console.log(e.domEvent.explicitOriginalTarget.innerText)
        let newLoc = {
          lat: e?.latLng.lat(),
          lng: e?.latLng.lng(),
          map_location:
            (await fetchLocationName(e?.latLng.lat(), e?.latLng.lng())) || '',
        };

        // console.log("newLoc", newLoc);

        setSourceLocation(newLoc);
        onPress(newLoc);
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={sourceLoc ? sourceLoc : center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
