import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.css";
function ProfileMenu() {
  const accessToken = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : false;
  const language = useSelector((state) => state.language.lang);
  return (
    <Fragment>
      {accessToken ? (
        <Link className="covertToLogin" to="/myaccount">
          <img
            src={accessToken?.data?.avatar}
            width={"40px"}
            style={{ borderRadius: "5px" }}
          />
          <span>{accessToken?.data?.name}</span>
        </Link>
      ) : (
        <Link className="covertToLogin" to="/login">
          <Icon icon="ep:user" />
          <span>{language === "ar" ? "تسجيل الدخول" : "Log In"}</span>
        </Link>
      )}
    </Fragment>
  );
}

export default ProfileMenu;
