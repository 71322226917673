import React, { useEffect } from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import DefaultLayout from "../../layout/defaultLayout";
import { ToastContainer } from "react-toastify";
import { fetchSiteData } from "../../store/SiteData";

function DownloadApps() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.lang);
  const checksitedata = useSelector((s) => s.SiteData);
  // console.log(checksitedata)
  useEffect(() => {
    dispatch(fetchSiteData(language));
  }, []);
  return (
    <DefaultLayout
      children={
        <div className="auth login">
          <Brudcrumb
            title={language === "ar" ? "حمل التطبيقات" : "Dowload Applications"}
          />
          <div
            className="loginFirst downappfirst"
            style={{ minHeight: "100vh" }}
          >
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1693824020/Capture-removebg-preview_sfi37k.png"
              alt=""
            />
            {/* {console.log("checksitedata.android", checksitedata.android)} */}
            {checksitedata.android ? (
              <div
                className="dowloadApp"
                onClick={() => window.open(checksitedata.android, "_blanck")}
              >
                <img
                  src="https://gfx4arab.com/wp-content/uploads/2020/06/google-play-download-android-app.svg"
                  alt=""
                />
              </div>
            ) : null}
            {checksitedata.ios ? (
              <div
                className="dowloadApp"
                onClick={() => window.open(checksitedata.ios, "_blanck")}
              >
                <img
                  src="https://www.seekpng.com/png/full/223-2231228_app-store-apple-transprent-download-on-apple-store.png"
                  alt=""
                />
              </div>
            ) : null}
            {checksitedata.huawei ? (
              <div
                className="dowloadApp"
                onClick={() => window.open(checksitedata.huawei, "_blanck")}
              >
                <img
                  src="https://money.pro/blog/2022/11/16/money-pro-on-appgallery/huawei.jpg"
                  alt=""
                />
              </div>
            ) : null}
          </div>
          <ToastContainer />
        </div>
      }
    />
  );
}

export default DownloadApps;
