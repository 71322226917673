import axios from "axios";
import { BASE_URL } from "../../CONSTS";

export const get_towns = async ({ options }) => {
  const towns = await axios.get(
    `${BASE_URL}api/web/v1/list`,
    options
  );
  return towns.data.data[0].city;
};
