import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../CONSTS";
let branch = localStorage.getItem("branch")
  ? JSON.parse(localStorage.getItem("branch"))
  : {};
// let branch = "77"

const initialState = {
  branch,
  branches: false,
  loading: false,
  err: false,
};

export const getBranched = createAsyncThunk("branch/getAll", async ({ language }) => {
  const options = {
    headers: {
      "Accept-Language": language
    }
  }
  const branches = await axios.get(
    `${BASE_URL}api/web/v1/site/branch`, options
  );
  return branches?.data?.data;
});

const branchSlice = createSlice({
  initialState,
  name: "branches",
  reducers: {
    updateBranch: (state, action) => {
      const branchData = JSON.parse(action.payload);

      const currentTime = new Date();

      const desiredOpenTime = new Date(currentTime);
      desiredOpenTime.setHours(...branchData?.open_time?.split(":"));

      const desiredCloseTime = new Date(currentTime);
      desiredCloseTime.setHours(...branchData?.closed_time?.split(":"));


      const openTime = currentTime >= desiredOpenTime;
      const closedTime = currentTime <= desiredCloseTime;

      // console.log(new Date().toLocaleTimeString('en-US'), branchData.open_time, branchData.closed_time, closedTime);
      if (closedTime && openTime) {
        state.branch = JSON.parse(action.payload);
        localStorage.setItem("branch", action.payload);
        localStorage.removeItem("talabaty");
        window.location.href = "/";
      } else {
        toast.error("Branch Closed In This Time")
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranched.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBranched.fulfilled, (state, action) => {
        state.branches = action.payload;
        state.loading = false;
      })
      .addCase(getBranched.rejected, (state, action) => {
        state.err = action.payload;
        state.loading = false;
      });
  },
});

export const { updateBranch } = branchSlice.actions;
export default branchSlice.reducer;
