import React, { Fragment } from "react";
import Header from "../header";
import Footer from "../footer";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";
import { ToastContainer } from "react-toastify";

function DefaultLayout({ children, classess, id }) {
  const getLang = useSelector((state) => state.language.lang);
  const branches = useSelector((state) => state.branches);

  return (
    <Fragment>
      <div
        // style={{ minHeight:'100vh' }}
        className={
          getLang === "ar"
            ? "siteContent arabicContent"
            : "siteContent EnglishContent"
        }
        dir={getLang === "ar" ? "rtl" : "ltr"}
      >
        <Header />
        <div
          className={classess ? classess.join(" ") + " childs" : "childs"}
          id={id ? id : null}
        >
          {branches.loading ? (
            <Loader size="md" content="" />
          ) : (
            <div>{children}</div>
          )}
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </Fragment>
  );
}

export default DefaultLayout;
