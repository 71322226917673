import React, { useEffect } from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { useSelector } from "react-redux";
import "./style.css";
import DefaultLayout from "../../layout/defaultLayout";
import { Button, Input, InputGroup, Loader, SelectPicker } from "rsuite";
import { Icon } from "@iconify/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { get_towns } from "../UserSystem/functions";
import { useState } from "react";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material/node";
import { BASE_URL } from "../../CONSTS";
function MyAccount() {
  const language = useSelector((state) => state.language.lang);
  const [towns, setTowns] = useState(false);

  const accessToken = localStorage.getItem("accessToken");
  // console.log(data)

  const data = accessToken ? JSON.parse(accessToken) : {};

  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteAcc = () => {
    setDeleteLoading(true);
    const formData = new FormData();
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    axios
      .post(`${BASE_URL}`, formData, options)
      .then((res) => {
        if (res.data.data.data[0].status) {
          toast.success(res.data.data.data[0].message);
        } else {
          toast.error(res.data.data.data[0].message);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setDeleteLoading(false));
  };

  const [updateloading, setupadteloading] = useState(false);

  const [usersystem, setusersystem] = useState({
    name: data?.data?.name,
    family_name: data?.data?.family_name,
    email: data?.data?.email,
    mobile: data?.data?.mobile,
    passwordupdate: data?.data?.passwordupdate,
    city: data?.data?.city,
    username: data?.data?.username,
    region: data?.data?.region,
    address: data?.data?.address,
    file: data?.data?.avatar
  });

  // console.log(usersystem);
  // const [loading,setloading]=useState(false);

  const handleupdate = () => {
    setupadteloading(true);
    const formdata = new FormData();
    formdata.append("name", usersystem.name);
    formdata.append("username", usersystem.username);
    formdata.append("family_name", usersystem.family_name);
    formdata.append("email", usersystem.email);
    formdata.append("mobile", usersystem.mobile);
    formdata.append("passwordupdate", usersystem.passwordupdate);
    formdata.append("city", usersystem.city);
    formdata.append("region", usersystem.region);
    formdata.append("address", usersystem.address);
    formdata.append("file", usersystem.file);
    formdata.forEach((value, key) => {
      // console.log(`${key}: ${value}`);
    });
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    // console.log("data", data);
    axios
      .post(
        `${BASE_URL}api/web/v1/site/profile?access-token=${data?.access_token}`,
        formdata,
        options
      )
      .then((res) => {
        // console.log(res.data);
        if (res?.data?.status == 1) {
          toast.success(
            language == 'ar' ? "تم التعديل بنجاح" : "لم يتم التعديل"
          );
          let newobj = {
            status: data?.status,
            message: data?.message,
            data: res?.data?.data[0]?.model,
            access_token: data?.access_token
          };
          // console.log(newobj);
          localStorage.setItem("accessToken", JSON.stringify(newobj));
        } else if (res?.data?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          language == 'ar'
            ? "حدث خطأ فى الإتصال"
            : "there are error in connection"
        );
      })
      .finally(() => {
        setupadteloading(false);
      });
  };

  const eqdata = () => {
    var citydata = [];
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    get_towns({ options }).then((res) => {
      // console.log(res)
      citydata = res.filter((item) => item?.id == data?.data?.city);
      // console.log(citydata)
    });
    // console.log(citydata);
    const mydata = {
      name: data?.data?.name,
      family_name: data?.data?.name,
      email: data?.data?.email,
      // mobile: data?.data?.mobile,
      passwordupdate: '',
      city: citydata[0]?.id,
      region: data?.data?.region,
      address: data?.data?.address,
      file: ''
    };

    get_towns({ options }).then((res) => {
      // console.log(res)
      citydata = res.filter((item) => item.id == data?.data?.city);
      mydata['city'] = citydata[0]?.id;
    });
  };
  const [showModelDeleteAcount, setShowModelDeleteAcount] = useState(false);
  if (showModelDeleteAcount) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  useEffect(() => {
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    get_towns({ options }).then((res) => {
      setTowns(
        res.map((item) => {
          return {
            label: language === "ar" ? item.name : item.name_en,
            value: item.id
          };
        })
      );
    });
    eqdata();
  }, []);

  // const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const getUser = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : false;

  return (
    <DefaultLayout
      children={
        <div className={!getUser ? "newacc" : "auth login"}>
          <Brudcrumb title={language === "ar" ? "حسابي" : "My Account"} />
          {!getUser ? (
            <div className="loginFirst">
              <div className="profileNotFound">
                <span>
                  <img
                    src={require("../../assests/icons/icon-user.svg").default}
                    alt=""
                  />
                </span>
                <p>
                  {language === "ar"
                    ? "يجب عليك تسجيل الدخول أولا"
                    : "Login First"}
                </p>
              </div>
              <Link className="signbtn" to="/login">
                {language === "ar" ? "تسجيل الدخول " : "Login"}
              </Link>
            </div>
          ) : (
            <div
              className="profile_found"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div className="pointnumber_div accountnumber">
                <div className="pointnumber">
                  <img src={require("../../assets/pointnumber.png")} alt="" />
                  <h4>{language === "ar" ? "عدد النقاط" : "points number"}</h4>
                </div>
                <div>
                  <h4>{data?.data?.point}</h4>
                  <h3>{language === "ar" ? "نقطه" : "point"}</h3>
                </div>
              </div>
              <div
                className="contact_Form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: '10px'
                }}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleupdate();
                  }}
                >
                  <div>
                    <label htmlFor="img">
                      {language === "ar" ? "صورة المستخدم" : "user image"}
                    </label>
                    <input
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          file: e.target.files[0]
                        });
                      }}
                      id="img"
                      type="file"
                      placeholder={
                        language === "ar" ? "صورة المستخدم" : "user avatar"
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="name">
                      {language === "ar" ? "إسم" : "name"}
                    </label>
                    <input
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          name: e.target.value
                        });
                      }}
                      value={usersystem?.name}
                      id="name"
                      type="text"
                      placeholder={language === "ar" ? "إسم" : "name"}
                    />
                  </div>

                  <div>
                    <label htmlFor="name">
                      {language === "ar" ? "إسم المستخدم" : "user name"}
                    </label>
                    {/* {console.log("username", usersystem)} */}
                    <input
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          username: e.target.value
                        });
                      }}
                      value={usersystem?.username}
                      id="name"
                      type="text"
                      placeholder={
                        language === "ar" ? "إسم المستخدم" : "user name"
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="name2">
                      {language === "ar" ? "إسم العائله" : "Name"}
                    </label>
                    <input
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          family_name: e.target.value
                        });
                      }}
                      id="name2"
                      type="text"
                      value={usersystem?.family_name}
                      placeholder={language === "ar" ? "الإسم" : "name"}
                    />
                  </div>
                  <div>
                    <label htmlFor="email">
                      {language === "ar" ? "البريد الاإلكترونى" : "Email"}
                    </label>
                    <input
                      onChange={(e) => {
                        setusersystem({ ...usersystem, email: e.target.value });
                      }}
                      id="email"
                      type="email"
                      value={usersystem?.email}
                      placeholder={
                        language === "ar" ? "البريد الاإلكترونى" : "Email"
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">
                      {language === "ar" ? "الجوال" : "Phone"}
                    </label>
                    <input
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          mobile: parseInt(e.target.value)
                        });
                      }}
                      value={usersystem?.mobile}
                      id="phone"
                      type="text"
                      placeholder={language === "ar" ? "الجوال" : "Phone"}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor="address">
                      {language === "ar" ? "العنوان التفصيلى" : "Address"}
                    </label>
                    <input
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          address: e.target.value
                        });
                      }}
                      value={usersystem?.address}
                      id="address"
                      type="text"
                      placeholder={
                        language === "ar" ? "العنوان التفصيلى" : "Address"
                      }
                    />
                  </div>
                  {towns ? (
                    <div style={{ marginBottom: '50px' ,borderRadius:'4px' }}>
                      <label htmlFor="city">
                        {language === "ar" ? "المدينه" : "City"}
                      </label>
                      <div className="city_div loc_ad">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          value={usersystem?.city}
                          onChange={(e) => {
                            setusersystem({
                              ...usersystem,
                              city: e.target.value
                            });
                          }}
                        >
                          {towns && towns.length
                            ? towns.map((item, index) => {
                                return (
                                  <MenuItem value={item?.value}>
                                    {item?.label}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <label htmlFor="district">
                      {language === "ar" ? "الحى" : "District"}
                    </label>
                    <input
                      value={usersystem.region}
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          region: e.target.value
                        });
                      }}
                      id="district"
                      type="text"
                      placeholder={language === "ar" ? "الحى" : "District"}
                    />
                  </div>
                  <div>
                    <label htmlFor="newpass">
                      {language === "ar"
                        ? "كلمة المرور الجديده"
                        : "New Password"}
                    </label>
                    <input
                      id="newpass"
                      onChange={(e) => {
                        setusersystem({
                          ...usersystem,
                          passwordupdate: e.target.value
                        });
                      }}
                      type="password"
                      placeholder={
                        language === "ar"
                          ? "كلمة المرور الجديدة"
                          : "new password"
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="message">
                      {language === "ar"
                        ? "معلومات إضافيه"
                        : "additional information"}
                    </label>
                    <textarea
                      placeholder={
                        language === "ar"
                          ? "معلومات إضافيه"
                          : "additional information"
                      }
                      id="message"
                    ></textarea>
                  </div>
                  {updateloading ? (
                    <div
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    <button className="editbtn" style={{ marginTop: '10px' }}>
                      {language === "ar" ? "حفظ التغيرات" : "Save Changes"}
                    </button>
                  )}
                </form>
                <button
                  onClick={() => {
                    setShowModelDeleteAcount(true);
                  }}
                  className="btn btn-danger"
                  style={{
                    margin: "auto",
                    padding: "10px 20px",
                    background: "#bb2d3b !important",
                    width: "100%"
                  }}
                >
                  {language === "ar" ? "حذف الحساب" : "Delete Account"}
                </button>
              </div>
            </div>
          )}

          {showModelDeleteAcount ? (
            <div className="del_acc">
              <div
                className="overlay"
                onClick={() => setShowModelDeleteAcount(false)}
              ></div>
              <div className="delete_account">
                <div
                  className="delete_acc"
                  style={{ fontSize: "20px", textAlign: "center" }}
                >
                  <div
                    className="tanbieeh"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      margin: "10px 0"
                    }}
                  >
                    <img
                      src="https://res.cloudinary.com/duovxefh6/image/upload/v1693827411/Capture-removebg-preview_il85an.png"
                      alt=""
                      width={"40px"}
                    />
                    <h4
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "var(--main-color)"
                      }}
                    >
                      تنبيه
                    </h4>
                  </div>
                  {language == "ar"
                    ? "هل أنت متأكد من حذف الحساب"
                    : "Are You Sure ?"}
                </div>
                <div className="btns">
                  <button
                    className="btn btn-primary"
                    style={{
                      width: "fit-content !important",
                      marginLeft: "0 auto 20px",
                      background: "var(--main-color)",
                      padding: "10px 20px",
                      border: "none"
                    }}
                    onClick={() => {
                      setDeleteLoading(false);
                      setShowModelDeleteAcount(false);
                    }}
                  >
                    {!deleteLoading ? (
                      <>{language === "ar" ? "إغلاق" : "Close"}</>
                    ) : (
                      <Loader />
                    )}
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{
                      margin: "auto",
                      background: "#bb2d3b !important",
                      padding: "10px 20px"
                    }}
                    onClick={() => {
                      return deleteLoading ? null : deleteAcc();
                    }}
                  >
                    {!deleteLoading ? (
                      <>{language === "ar" ? "حذف" : "Delete"}</>
                    ) : (
                      <Loader />
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <ToastContainer />
        </div>
      }
    />
  );
}

export default MyAccount;
