import React, { useEffect, useState } from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { useDispatch, useSelector } from "react-redux";
import DefaultLayout from "../../layout/defaultLayout";
import { Input, InputGroup } from "rsuite";
import { Icon } from "@iconify/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import {
  removeCartItem,
  updateCartData,
  updateCartItem,
  updateCartNotes
} from "../../store/cartReducer";
import "./style.css";
import "../home/style.css";
import { BASE_URL } from "../../CONSTS";

function Cart() {
  const accessToken = localStorage.getItem("accessToken");
  const data = accessToken ? JSON.parse(accessToken) : null;
  const getBranch = useSelector((state) => state.branches.branch);
  // console.log(getBranch)
  // console.log(data?.access_token)
  const product = useSelector((s) => s.cart);

  const [payments, setpayments] = useState([]);

  const [paytype, setpaytype] = useState("");
  const [delitype, setdelitype] = useState("");
  const notesRef = useRef();

  const getpaymentmethods = () => {
    const options = {
      headers: {
        'Accept-Language': "ar" // Set the desired language code here
      }
    };
    axios
      .get(
        `${BASE_URL}api/web/v1/list/card-type`,
        options
      )
      .then((res) => {
        setpayments(res.data.data[0].CardType);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getpaymentmethods();
  }, []);

  const language = useSelector((state) => state.language.lang);
  const checksitedata = useSelector((s) => s.SiteData);
  const navigate = useNavigate();
  const getUser = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : false;
  const getCart = useSelector((state) => state.cart.data);
  const getCartData = useSelector((state) => state.cart);
  const [getCartItems, setCartItems] = useState([]);
  useEffect(() => {
    setCartItems(getCart);
    // console.log(getCart);
  }, [getCart, getCartData]);
  const dispatch = useDispatch();
  const [taxvalue, settaxvalue] = useState(0);
  const [minmalData, setMinimalData] = useState(false);

  const getminimal = () => {
    axios
      .get(`${BASE_URL}api/web/v1/product/order-limit`)
      .then((res) => {
        // console.log(res.data.data.tax);
        settaxvalue(res.data.data.tax);
        setMinimalData(res.data.data);
      });
  };

  useEffect(() => {
    getminimal();
  }, []);

  const handleaddorder = () => {
    const formdata = new FormData();
    formdata.append("username", data.data.username);
    formdata.append("country", data.data.city);
    formdata.append("region", data.data.region);
    formdata.append("address", product.address);
    formdata.append("mobile", data.data.mobile);
    formdata.append("description", product.description);
    formdata.append("status", product.paymentstatus);
    formdata.append("order_time", product.order_time);
    formdata.append("branch_id", getBranch.id);
    formdata.append("delivery_status", product.waydeliv);
    formdata.append("totalprice", product.product_totalPrice);
    formdata.append("number_of_items", product.product_quantity);
    formdata.append("totalquantity", product.totalquantity);
    formdata.append("product_ids", product.product_ids);
    formdata.append("product_name", product.product_name);
    formdata.append("product_price", product.product_price);
    formdata.append("product_quantity", product.product_quantity);
    formdata.append("product_extension", product.product_extension);
    formdata.append("product_size", product.product_size);
    formdata.append("product_rice", product.product_rice);
    formdata.append("product_dish", product.product_dish);
    formdata.append("product_totalPrice", product.product_totalPrice);
    formdata.append("lat", product.lat);
    formdata.append("lang", product.lang);
    formdata.append("copun", '');
    // console.log(formdata);
    axios
      .post(
        `${BASE_URL}api/web/v1/product/checkout?access-token=${data?.access_token}`,
        formdata
      )
      .then((res) => {
        // console.log(res.data.data)
        if (res?.data?.data[0]?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else if (res?.data?.data[0]?.status == 1) {
          toast.success(res?.data?.data[0]?.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "something wen error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [dataobj, setdataobj] = useState({});

  const getthemsdata = () => {
    const options = {
      headers: {
        'Accept-Language': language // Set the desired language code here
      }
    };
    axios
      .get(
        `${BASE_URL}api/web/v1/product/style`,
        options
      )
      .then((res) => {
        // console.log(res.data.data[0]);
        setdataobj(res.data.data[0]);
      });
  };
  useEffect(() => {
    getthemsdata();
  }, []);

  useEffect(() => {
    if (
      product?.type == "" ||
      product?.type == null ||
      product?.type == undefined
    ) {
      setpaytype("");
    } else {
      setpaytype(product?.type);
    }

    if (
      product?.waydeliv !== null &&
      product?.waydeliv !== "" &&
      product?.waydeliv !== undefined
    ) {
      if (product?.waydeliv == 1) {
        setdelitype(1);
      } else {
        setdelitype(0);
      }
    } else {
      setdelitype("");
    }
    // console.log(product, "wd");
  }, []); //product?.type

  return (
    <DefaultLayout
      children={
        <div className="">
          <Brudcrumb title={language === "ar" ? "السلة" : "Cart"} />
          {!getCartItems || !getCartItems.length ? (
            <div className="loginFirst">
              <div className="profileNotFound">
                <span>
                  <img
                    src={require("../../assests/icons/icon-cart.svg").default}
                    alt=""
                  />
                </span>
                <p>
                  {language === "ar" ? "سلة التسوق فارغة" : "Cart is empty"}
                </p>
              </div>
            </div>
          ) : (
            <div className="cartItems_container">
              <div className="product_text">
                <div className="products_card">
                  <div className="products_cards_container">
                    {getCartItems.map((item, index) => {
                      // console.log(item)
                      return (
                        <div
                          className="product_card"
                          style={{ border: ".5px solid #E2E2E2" }}
                          key={index}
                        >
                          <div>
                            <div className="product_image">
                              <img src={item.item.image} alt="" />
                            </div>
                            <div className="product_details">
                              <div className="product_text">
                                <div>
                                  <p style={{ fontSize: "22px" }}>
                                    {language === "ar"
                                      ? item?.item?.title
                                      : item?.item?.title_en}
                                  </p>
                                  {item.selectedSize[0] != undefined ? (
                                    <p style={{ fontSize: "17px" }}>
                                      {language === "ar" ? "الحجم" : "Size"}{" "}
                                      {"   "}{" "}
                                      <span
                                        style={{
                                          color: "var(--main-color)",
                                          fontSize: "17px",
                                          display: "inline-block",
                                          margin: "0 10px"
                                        }}
                                      >
                                        <>
                                          {item?.selectedSize[0]?.size_name} (
                                          {parseInt(
                                            item?.selectedSize[0]?.dis_price
                                          )
                                            ? parseFloat(
                                                item?.selectedSize[0]?.dis_price
                                              )
                                            : parseFloat(
                                                item?.selectedSize[0]?.price
                                              )}{" "}
                                          SR){" "}
                                        </>
                                      </span>
                                    </p>
                                  ) : null}
                                </div>
                                <span
                                  className="discount"
                                  style={{
                                    padding: "5px",
                                    borderRadius: "4px",
                                    background: "#D31E4B",
                                    color: "white",
                                    width: "46px",
                                    height: "45px",
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                    textAlign: "center"
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      removeCartItem({
                                        cartProductId: item?.cartProductId
                                      })
                                    );
                                    dispatch(updateCartData());
                                  }}
                                >
                                  <img
                                    src={
                                      require("../../assests/icons/delete.svg")
                                        .default
                                    }
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div
                                className="product_price_details"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start"
                                }}
                              >
                                <div className="ext_c">
                                  <div className="extenxtion_c">
                                    {item.selectedExtensions &&
                                    item.selectedExtensions.length ? (
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {" "}
                                          {language === "ar"
                                            ? "الإضافات"
                                            : "Extensions"}{" "}
                                        </h6>
                                        <ol>
                                          {item.selectedExtensions.map(
                                            (item, index) => {
                                              return (
                                                <li key={index}>
                                                  {" "}
                                                  {item?.extension_name} ({" "}
                                                  {parseInt(item?.dis_price)
                                                    ? parseFloat(
                                                        item?.dis_price
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      )}{" "}
                                                  SR)
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="extenxtion_c">
                                    {item.selectedDishes &&
                                    item.selectedDishes.length ? (
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {" "}
                                          {language === "ar"
                                            ? "الأطباق"
                                            : "Dishes"}{" "}
                                        </h6>
                                        <ol>
                                          {item.selectedDishes.map(
                                            (item, index) => {
                                              return (
                                                <li key={index}>
                                                  {" "}
                                                  {item?.dish_name} ({" "}
                                                  {parseInt(item?.dis_price)
                                                    ? parseFloat(
                                                        item?.dis_price
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      )}{" "}
                                                  SR)
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="extenxtion_c">
                                    {item.selectedRice &&
                                    item.selectedRice.length ? (
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {" "}
                                          {language === "ar"
                                            ? "الأرز"
                                            : "Rice"}{" "}
                                        </h6>
                                        <ol>
                                          {item.selectedRice.map(
                                            (item, index) => {
                                              return (
                                                <li key={index}>
                                                  {" "}
                                                  {item?.rice_name} ({" "}
                                                  {parseInt(item?.dis_price)
                                                    ? parseFloat(
                                                        item?.dis_price
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      )}{" "}
                                                  SR)
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                {item?.notes && item?.notes?.length ? (
                                  <span>
                                    {language === "ar" ? "الملاحظات" : "Notes"}{" "}
                                    {"   "}{" "}
                                    <span
                                      style={{
                                        color: "grey",
                                        fontSize: "13px",
                                        display: "inline-block",
                                        margin: "0 10px",
                                        whiteSpace: "pre-wrap"
                                      }}
                                    >
                                      {item?.notes}
                                    </span>
                                  </span>
                                ) : null}
                                <div className="changePrice">
                                  {/* {console.log(item)} */}
                                  <div
                                    style={{
                                      backgroundColor: checksitedata.mainColor,
                                      color: 'white',
                                      border: 'none'
                                    }}
                                    className="addPrice price_ch"
                                    onClick={() => {
                                      dispatch(
                                        updateCartItem({
                                          cartProductId: item.cartProductId,
                                          type: "increase"
                                        })
                                      );
                                      dispatch(updateCartData());
                                    }}
                                  >
                                    +
                                  </div>
                                  <div className="quantity">
                                    {item.quantity}
                                  </div>
                                  <div
                                    className="decreasePrice price_ch"
                                    onClick={() => {
                                      dispatch(
                                        updateCartItem({
                                          cartProductId: item.cartProductId,
                                          type: "decrease"
                                        })
                                      );
                                      dispatch(updateCartData());
                                    }}
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="edit_in_cart">
                            <div className="changePrice">
                              {language == "ar" ? "السعر" : "Price"}{" "}
                              <span
                                style={{
                                  color: "var(--main-color)",
                                  fontSize: "17px",
                                  display: "inline-block",
                                  margin: "0 10px"
                                }}
                              >
                                SAR {"  "}{item?.singlePrice?.toFixed(2)}
                              </span>
                            </div>
                            <div>
                              {language == "ar" ? "المجموع" : "Total price"}
                              <span
                                style={{
                                  color: "var(--main-color)",
                                  fontSize: "17px",
                                  display: "inline-block",
                                  margin: "0 10px"
                                }}
                              >
                                SAR {"  "}{item?.totalPrice?.toFixed(2)}
                              </span>
                            </div>
                            <ToastContainer />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            
              <div className="edit_in_cart edit_in_cart_c">
                <div style={{ margin: 'auto' }} className="changePrice">
                  {language == "ar" ? "السعر" : "Price"}{" "}
                  <span
                    style={{
                      color: "var(--main-color)",
                      fontSize: "17px",
                      display: "inline-block",
                      margin: "0 10px"
                    }}
                  >
                    SAR{" "}
                    {parseInt(getCartData?.product_all_totalPrice)
                      ? getCartData?.product_all_totalPrice?.toFixed(2)
                      : getCartData?.product_all_totalPrice}
                  </span>
                </div>
                <div>
                  {language == "ar" ? "المجموع الكلي" : "Total Summation"}{" "}
                  {language == "ar"
                    ? "شامل ضريبة القيمة المضافة" + minmalData?.tax + "%"
                    : "With Vat " + minmalData?.tax + "%"}
                  <span
                    style={{
                      color: "var(--main-color)",
                      fontSize: "17px",
                      display: "inline-block",
                      margin: "0 10px"
                    }}
                  >
                    SAR{" "}
                    {parseInt(getCartData?.product_all_totalPrice)
                      ? getCartData?.product_all_totalPrice?.toFixed(2)
                      : getCartData?.product_all_totalPrice}
                  </span>
                </div>
              </div>
              <div className="order_btns">
                <button
                  className="continue_o"
                  onClick={() => {
                    navigate("/talabaty");
                  }}
                >
                  {language == "ar" ? "تابع عملية الشراء" : "Continue Buying"}
                </button>
                <button className="add_more_o" onClick={() => navigate("/")}>
                  {language == "ar" ? "أضف المزيد" : "Add More"}
                </button>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

export default Cart;
