import { Icon } from "@iconify/react";

export const footerLinks = [
  {
    label: "العروض",
    label_en: "Offers",
    route: "/offers",
  },
  {
    label: "فروعنا",
    label_en: "Our Branches",
    route: "/branches",
  },
  {
    label: "حمل التطبيقات",
    label_en: "Download Apps",
    route: "/downloadApps",
  },
  {
    label: "تواصل معنا",
    label_en: "contact us",
    route: "/contact",
  },
];

export const socialFooter = [
  {
    link: "https://www.facebook.com",
    icon: <Icon icon="jam:facebook" color="var(--main-color)" />,
  },
  {
    link: "https://www.twitter.com",
    icon: <Icon icon="fa6-brands:twitter" color="var(--main-color)" />,
  },
  {
    link: "https://www.instagram.com",
    icon: <Icon icon="icon-park-outline:instagram" color="var(--main-color)" />,
  },
  {
    link: "https://www.youtube.com",
    icon: <Icon icon="grommet-icons:youtube" color="var(--main-color)" />,
  },
];
