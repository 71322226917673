import React, { useState } from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { useSelector } from "react-redux";
import "./style.css";
import DefaultLayout from "../../layout/defaultLayout";
import { Input, InputGroup, Loader } from "rsuite";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../CONSTS";
function Signup() {
  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const signUp = async (e) => {
    // console.log(e);
    const formData = new FormData(e.currentTarget);
    const mobile = e.currentTarget.mobile.value;
    setLoading(true);
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    await axios
      .post(
        `${BASE_URL}api/web/v1/site/signup`,
        formData,
        options
      )
      .then((res) => {
        if (res.data.data[0].status) {
          toast.success(res.data.data[0].message);
          navigate("/verifyaccount", { state: mobile });
        } else {
          toast.error(res.data.data[0].message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setLoading(false);
  };
  const [showPass, setShowPass] = useState(false);
  return (
    <DefaultLayout
      children={
        <div className="auth login rig-auth">
          <Brudcrumb
            title={language === "ar" ? "اشتراك جديد" : "New Account"}
          />
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              signUp(e);
            }}
          >
            <div className="inputField">
              <label htmlFor="name">
                {language === "ar" ? "الاسم الاول" : "First Name"}
              </label>
              <InputGroup>
                <Input type="text" name="name" required />
              </InputGroup>
            </div>

            <div className="inputField">
              <label htmlFor="family_name">
                {language === "ar" ? "اسم العائلة" : "Family Name"}
              </label>
              <InputGroup>
                <Input type="text" name="family_name" required />
              </InputGroup>
            </div>

            <div className="inputField">
              <label htmlFor="email">
                {language === "ar" ? "البريد الالكتروني" : "Email"}
              </label>
              <InputGroup>
                <Input type="text" name="email" required />
              </InputGroup>
            </div>

            <div className="inputField">
              <label htmlFor="mobile">
                {language === "ar" ? "رقم الجوال" : "Phone Number"}
              </label>
              <InputGroup>
                <Input type="text" name="mobile" required />
              </InputGroup>
            </div>

            <div className="inputField">
              <label htmlFor="password">
                {language === "ar" ? "الرقم السري" : "Password"}
              </label>
              <InputGroup>
                <Input
                  type={!showPass ? "password" : "text"}
                  name="password"
                  required
                />
                <InputGroup.Addon
                  onClick={() => setShowPass(!showPass)}
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    color: "var(--main-color)"
                  }}
                >
                  <Icon icon="carbon:view" required />
                </InputGroup.Addon>
              </InputGroup>
            </div>

            <div className="inputField">
              <label htmlFor="newPasswordConfirm">
                {language === "ar" ? "تأكيد الرقم السري " : "Password"}
              </label>
              <InputGroup>
                <Input
                  type={!showPass ? "password" : "text"}
                  name="newPasswordConfirm"
                  required
                />
                <InputGroup.Addon
                  onClick={() => setShowPass(!showPass)}
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    color: "var(--main-color)"
                  }}
                >
                  <Icon icon="carbon:view" required />
                </InputGroup.Addon>
              </InputGroup>
            </div>

            <div
              className="terms_and_con"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <Input
                type="checkbox"
                name="checkbox"
                style={{ width: "50px" }}
                required
              />
              <label htmlFor="checkbox" style={{ color: "var(--main-color)" }}>
                <Link to={"/condition"} target="_blanck">
                  {language === "ar"
                    ? " أوافق على الشروط والأحكام "
                    : "Accept Terms And Conditions"}
                </Link>
              </label>
            </div>
            <button className="btn auth-btn rig-auth-btn">
              {loading ? (
                <Loader />
              ) : (
                <span>{language === "ar" ? "تسجيل" : "Signup"}</span>
              )}
            </button>
            <span
              className="btn"
              style={{
                background: "white",
                color: "var(--main-color)",
                border: "4px dotted var(--main-color)",
                margin: "5px 0",
                width: "100%"
              }}
              onClick={() => navigate("/login")}
            >
              {language === "ar"
                ? "أو لديك حساب يمكنك الدخول إليه"
                : "Login if you have an account"}
            </span>
          </form>
          <ToastContainer />
        </div>
      }
    />
  );
}

export default Signup;
