import React, { useState } from "react";
import "./style.css";
import SideBar from "../sidebar";
import { useSelector } from "react-redux";
import ProfileMenu from "../../components/profileMenu";
import { Icon } from "@iconify/react";
import Branches from "../../components/branches";
import LanguageConverter from "../../components/languageConverter";
import { Link } from "react-router-dom";
import "./style.css"



function Header() {
  const getCartData = useSelector((state) => state.cart.data);
  // console.log(getCartData)
  // console.log(getCartData.number_of_items)
  const [show, setShow] = useState(false);
  return (
    <div className="header_father">
      <header>
      <div className="header_co">
        <span
          id="toggle_side"
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => setShow(!show)}
        >
          {" "}
          <Icon icon="ph:list" />{" "}
        </span>
        <Branches />
      </div>
      <SideBar show={show} setShow={setShow} />
      <div className="header_co">
        <LanguageConverter />
        <Link to={"/cart"} className="cart">
          <p>{getCartData?getCartData?.length:0}</p>
        <img src={require("../../assests/icons/icon-cart.svg").default}/>
        </Link>
        <ProfileMenu />
      </div>
    </header>
    </div>
  );
}

export default Header;
