export const headerData = [
  {
    label: "الرئيسية",
    route: "/",
    label_en: "Home",
  },
  {
    label: "حسابي",
    route: "/myaccount",
    label_en: "My Account",
  },
  {
    label: "قائمة المفضلة",
    route: "/favorites",
    label_en: "Favourites",
  },
  {
    label: "العروض",
    route: "/offers",
    label_en: "Offers",
  },
  {
    label: "الفروع",
    route: "/branches",
    label_en: "Branches",
  },
  {
    label: "عناويني",
    route: "/myaddresses",
    label_en: "My Addresses",
  },
  {
    label: "طلباتي",
    route: "/mypurchases",
    label_en: "My  Orders",
  },
  {
    label: "من نحن",
    route: "/knowus",
    label_en: "About Us",
  },
  {
    label: "تواصل معنا",
    route: "/contact",
    label_en: "Contact Us",
  },
  {
    label: "الشروط والأحكام",
    route: "/condition",
    label_en: "Terms and Conditions",
  },
];
