import React, { useEffect } from "react";
import "./stryle.css";
import { headerData } from "../../data/headerdata";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./stryle.css";
import { Button } from "rsuite";
import { Icon } from "@iconify/react";
import { fetchSiteData } from "../../store/SiteData";
function SideBar({ show, setShow }) {
  const dispatch=useDispatch();
  const language = useSelector((state) => state.language.lang);
// console.log(localStorage.getItem("accessToken"))
  const accessToken = localStorage.getItem("accessToken") && localStorage.getItem("accessToken")
    ? JSON.parse(localStorage?.getItem("accessToken"))
    : {};
  const navigate = useNavigate();
  const checksitedata=useSelector(s=>s.SiteData)
  // console.log(checksitedata)
  useEffect(()=>{
    dispatch(fetchSiteData(language))
  },[])
  return (
    <aside
      id={language === "ar" ? "aside_ar" : "aside_en"}
      className={show ? "active" : null}
    >
      {show ? (
        <div className="dark_b" onClick={() => setShow(false)}></div>
      ) : null}

      <div className="container_co">
        <div className="logo_we">
          <img
            src={checksitedata.logo}
            alt=""
          />
        </div>
        {!Object.keys(accessToken).length>0? (
          <div

            className="profile_found"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              style={{ background: "#d9d9d9", color: "black" }}
              onClick={() => navigate("/signup")}
            >
              {" "}
              <img
                src={require("../../assests/icons/icon-user.svg").default}
                style={{ margin: "0 6px", width: "15px" }}
              />
              {"       "}
              {language === "ar" ? "حساب جديد" : "New Account"}
              {"    "}
            </Button>
            <Button
              style={{ background: "#d9d9d9", color: "black" }}
              onClick={() => navigate("/login")}
            >
              {" "}
              <img
                src={require("../../assests/icons/icon-user.svg").default}
                style={{ margin: "0 6px", width: "15px" }}
              />
              {"       "}
              {language === "ar" ? "تسجيل الدخول" : "LogIn"}
            </Button>
          </div>
        ) : (
          <Button
            style={{
              background: "#d9d9d9",
              color: "black",
              display: "flex",
              gap: "10px",
              width: "fit-content !important",
              margin: "16px auto",
              padding: "10px 17px",
              fontSize: "17px",
            }}
            onClick={() => navigate("/myaccount")}
          >
            <Icon icon="ep:user" />
            <span>{accessToken?.data?.username}</span>
          </Button>
        )}
        <div className="container-coo">
          {headerData.map((item, index) => {
            return (
              <a href={item.route} key={index}>
                {language === "ar" ? item.label : item.label_en}
              </a>
            );
          })}
          {Object.keys(accessToken).length>0 ? (
            <NavLink
              onClick={() => {
                localStorage.removeItem("accessToken");
                window.location.reload();
                localStorage.removeItem("branch");
                localStorage.removeItem("talabaty");
              }}
            >
              {" "}
              {language === "ar" ? "تسجيل الخروج" : "Log out"}{" "}
            </NavLink>
          ) : null}
        </div>
      </div>
    </aside>
  );
}

export default SideBar;
