import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../CONSTS";

const initialState={
}
export const fetchSiteData= createAsyncThunk("Site/data",async()=>{
  // const options = {
  //   headers: {
  //     'Accept-Language': action.language // Set the desired language code here
  //   }
  // };
  const data= await axios.get(`${BASE_URL}api/web/v1/product/style`)
  // console.log(data)
  return data.data.data[0];
})
export const SiteDataSlice=createSlice({
  name:'sitedata',
  initialState,
  reducers:{},
  extraReducers:(builder)=>{
    builder.addCase(fetchSiteData.fulfilled,(state,action)=>{
      return action.payload;
    })
  }
})


export const {}=SiteDataSlice.actions;

export default SiteDataSlice.reducer;
