import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Loader } from 'rsuite';

const containerStyle = {
  width: '400px',
  height: '300px'
};

const center = {
  lat: 30.79104837723118,
  lng: 31.003953975123654
};

function MyComponent({ onPress, locdata, setLocationName }) {
  // console.log("locdata", locdata);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBaUmZqLrBQj3LQHChTJOHJuqvDbg_lbAU"
  });

  const [map, setMap] = React.useState(null);
  const [sourceLoc, setSourceLocation] = useState({});

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const fetchLocationName = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: lat, lng: lng };

    try {
      const response = await geocoder.geocode({ location: latlng });
      // console.log(response);
      if (response?.results && response?.results.length > 0) {
        const locationName = response.results[0]?.formatted_address;
        setLocationName({
          map_location: response.results[0]?.formatted_address,
          latlng
        });
        return locationName;
      }
    } catch (error) {
      console.error('Error fetching location name:', error);
    }

    return ''; // Return an empty string if location name couldn't be fetched
  };
  const center = {
    lat: parseFloat(locdata?.lat),
    lng: parseFloat(locdata?.lng)
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ LatLng: center }}
      zoom={3}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={async (e) => {
        // console.log(e)
        // console.log(e.domEvent.explicitOriginalTarget.innerText)
        let newLoc = {
          lat: e?.latLng.lat(),
          lng: e?.latLng.lng(),
          map_location: await fetchLocationName(
            e?.latLng.lat(),
            e?.latLng.lng()
          )
        };
        // console.log(newLoc)
        setSourceLocation(newLoc);
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {sourceLoc && <Marker position={sourceLoc ? sourceLoc : center} />}
    </GoogleMap>
  ) : (
    <>
      <Loader />
    </>
  );
}

export default React.memo(MyComponent);
