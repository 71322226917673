import React from "react";
import "./style.css";
import { footerLinks, socialFooter } from "../../data/footerData";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { SocialIcon } from "react-social-icons";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { BASE_URL } from "../../CONSTS";
function Footer() {
  const language = useSelector((state) => state.language.lang);

  const [data, setdata] = useState({});

  const getthemsdata = () => {
    axios
      .get(`${BASE_URL}api/web/v1/product/style`, {
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
          'Accept-Language': language == "ar" ? "ar" : "en"
        }
      })
      .then((res) => {
        // console.log(res.data.data[0]);
        setdata(res.data.data[0]);
      });
  };
  useEffect(() => {
    getthemsdata();
  }, []);
  return (
    <footer>
      <ul className="footerLinks">
        {footerLinks.map((item, index) => {
          return (
            <NavLink
              style={{ color: "var(--main-color)" }}
              to={item.route}
              key={index}
            >
              {language === "ar" ? item.label : item.label_en}
              {index != footerLinks.length - 1 ? <span> | </span> : null}
            </NavLink>
          );
        })}
      </ul>
      <ul className="socialLinks">
        {/* {socialFooter.map((item, index) => {
          return (
            <NavLink to={item.link} key={index}>
              {item.icon}
            </NavLink>
          );
        })
        <NavLink to={item.link} key={index}>
        {item.icon}
      </NavLink>
        } */}
        {data.facebook ? (
          <NavLink to={data.facebook} target="_blanck">
            <Icon icon="jam:facebook" color="var(--main-color)" />
          </NavLink>
        ) : null}
        {data.twitter ? (
          <NavLink to={data.twitter} target="_blanck">
            <Icon
              icon="icon-park-outline:instagram"
              color="var(--main-color)"
            />
          </NavLink>
        ) : null}
        {data.twitter ? (
          <NavLink to={data.twitter} target="_blanck">
            <Icon icon="icon-park-outline:twitter" color="var(--main-color)" />
          </NavLink>
        ) : null}
        {data.youtube ? (
          <NavLink to={data.youtube} target="_blanck">
            <Icon icon="icon-park-outline:youtube" color="var(--main-color)" />
          </NavLink>
        ) : null}
        {data.snapchat ? (
          <NavLink to={data.snapchat} target="_blanck">
            <Icon icon="ic:twotone-snapchat" color="var(--main-color)" />
          </NavLink>
        ) : null}
      </ul>
      <div
        className="footer_b"
        style={{
          color: "var(--main-color)",
          padding: "10px",
          fontSize: "17px",
          display: "flex",
          justifyContent: "space-between",
          width: "min(850px, 100%) !important"
        }}
      >
        <img src={require("../../assests/icons/bestin.svg").default} alt="" />
        <p>
          &copy;
          {language === "ar"
            ? " جميع الحقوق محفوظة لدي "
            : " All rights reserved "}
          Best In
        </p>
      </div>
    </footer>
  );
}

export default Footer;
